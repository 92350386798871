import React, { FC } from 'react';
import { PageProps } from 'gatsby';

// components
import DynamicComponent from '@components/DynamicComponent';

// layout
import Layout from '@layout';

// templates
import Redirect from '@templates/404';

// lib
import useStoryblok, { isStory } from '../hooks/useStoryblok';
import { isProd, isProductionBranch } from '@utils/environments';

type NotFoundProps = {
  location: any;
};

const NotFound: FC<PageProps<NotFoundProps>> = ({ location }) => {
  const [story] = useStoryblok({ story: { content: '{}' } as any }, location.pathname);
  /**
   * redirect back to homepage if the page doesn't exist in production
   */
  if (isProd() && isProductionBranch())
    return (
      <Layout location={location}>
        <Redirect />
      </Layout>
    );

  return (
    <Layout location={location}>
      {isStory(story) ? <DynamicComponent blok={story.content} key={story.uuid} /> : <div />}
    </Layout>
  );
};

export default NotFound;
