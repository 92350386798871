import React, { useEffect } from 'react';
import { navigate } from '@reach/router';

export default function NotFound(): JSX.Element {
  useEffect(() => {
    navigate('/', { replace: true }).then(() => location.reload());
  }, []);

  return (
    <div>
      <h1>Hello Page not found</h1>
    </div>
  );
}
