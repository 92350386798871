/**
 * returns the storyblok space name that is associated
 * to the environment.
 * @returns `development` | `sandbox` | `production`
 */
export function getStoryblokBranchName() {
  return process.env.STORYBLOK_BRANCH_NAME;
}

export function getPreviewBranchAccessToken() {
  // preview tokens
  return process.env.STORYBLOK_LOCAL_PREVIEW_TOKEN;
}

export function getProductionBranchAccessToken() {
  // public tokens
  return process.env.STORYBLOK_PRODUCTION_PUBLIC_TOKEN;
}

export function isProductionBranch() {
  return getStoryblokBranchName() === 'PRODUCTION';
}

export function isProd() {
  return process.env.NODE_ENV === 'production';
}

export function isDev() {
  return process.env.NODE_ENV === 'development';
}
